<template>
  <v-dialog v-model="visibility" width="720px" persistent>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <div style="padding: 20px; background-color: #fff">
      <v-row
        no-gutters
        style="border-bottom: 1px solid #e5e9eb; padding-bottom: 20px"
      >
        <span
          style="
            color: #1b1b1b;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          "
          >{{
            !isNextStep && !isEdit ? "Створення знижки" : `${promotion.name}`
          }}</span
        >
        <v-row no-gutters justify="end">
          <v-icon color="#1B1B1B" @click="$emit('close')" size="32px"
            >mdi-close</v-icon
          >
        </v-row>
      </v-row>
      <v-col class="px-0 py-0" v-if="!isNextStep">
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px; margin-top: 32px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Назва знижки"
          placeholder="Введіть назву.."
          v-model="promotion.name"
          :error-messages="nameError"
        />
        <v-autocomplete
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Тип"
          placeholder="Виберіть тип"
          :items="Object.values(discountPeriodList)"
          :item-text="'name'"
          :item-value="'value'"
          v-model="promotion.discount_type"
          :error-messages="discountTypeError"
        />
        <v-autocomplete
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Виберіть на що діє знижка"
          placeholder="Вибіреть дію"
          :items="Object.values(typeDiscountActionList)"
          :item-text="'name'"
          :item-value="'value'"
          v-model="promotion.route_type"
          :error-messages="routeTypeError"
        />
      </v-col>
      <v-col v-else class="px-0 py-0">
        <v-row no-gutters>
          <v-col class="py-0" cols="8" style="padding-right: 10px">
            <v-autocomplete
              outlined
              dense
              height="50px"
              style="border-radius: 10px; margin-top: 32px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Тип знижки"
              placeholder="Виберіть тип"
              v-model="promotion.amount_type"
              :error-messages="amountTypeError"
              :items="discountType"
              :item-text="'name'"
              :item-value="'value'"
            />
          </v-col>
          <v-col class="py-0" cols="4" style="padding-left: 10px">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px; margin-top: 32px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              :label="
                promotion.amount_type == 'Percent'
                  ? 'Відсоток знижки'
                  : 'Cума(грн)'
              "
              :placeholder="
                promotion.amount_type == 'Percent'
                  ? 'Введіть %..'
                  : 'Введіть суму(грн)'
              "
              v-model="promotion.amount"
              :error-messages="amountError"
            />
          </v-col>
        </v-row>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Період дії початку\закінчення"
              placeholder="Виберіть період"
              v-model="promotion.dates"
              :error-messages="datesError"
              v-bind="attrs"
              v-on="on"
              v-if="
                promotion.discount_type == 'Season' ||
                promotion.discount_type == 'usage_limit'
              "
            />
          </template>
          <div style="background: #fff">
            <v-row no-gutters justify="center">
              <v-date-picker
                v-model="promotion.dates"
                range
                color="#149E51"
                no-title
                :key="datePickerKey"
                :min="minDate"
              ></v-date-picker>
            </v-row>
          </div>
        </v-menu>
        <v-autocomplete
          v-if="
            promotion.route_type == 'Selected' ||
            promotion.route_type == 'Schedule'
          "
          outlined
          dense
          multiple
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Вибір маршруту"
          placeholder="Виберіть маршрут"
          :items="routesList"
          :item-text="
            (item) =>
              `${
                item?.departure.translations?.find(
                  (translate) => translate.lang == 'ua'
                ).name
              }-${
                item?.destination?.translations?.find(
                  (translate) => translate.lang == 'ua'
                ).name
              }`
          "
          :item-value="'id'"
          v-model="promotion.routes"
          :error-messages="routesError"
        />
        <v-autocomplete
          v-if="promotion.route_type == 'Schedule'"
          outlined
          dense
          multiple
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Вибір рейсу"
          placeholder="Виберіть рейс"
          v-model="promotion.schedules"
          :items="Object.values(scheduleList)"
          :item-text="
            (item) =>
              `${new Date(item.departed_at).toLocaleDateString('uk-UA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                 timeZone: 'Europe/Kiev'
              })} - ${new Date(item.arrival_at).toLocaleDateString('uk-UA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                 timeZone: 'Europe/Kiev'
              })} (${item?.route?.departure?.translations?.find((translate) => translate.lang == 'ua')?.name} - ${
                item?.route?.destination?.translations?.find((translate) => translate.lang == 'ua')?.name
              })`
          "
          :item-value="'id'"
          :disabled="promotion.routes == ''"
          :error-messages="routesError"
        >
      </v-autocomplete>
        <v-row no-gutters align="center">
          <v-col cols="4" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              height="50px"
              style="border-radius: 10px"
              color="#C0C0C0"
              background-color="#FCFCFC"
              label="Кількість"
              placeholder="Введіть кількість.."
              v-model="promotion.usage_limit"
              hide-details
            />
          </v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="helpIcon"
                style="margin-left: 8px"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            Введіть, щоб обмежити кількість використань
          </v-tooltip>
        </v-row>
      </v-col>
      <submit-button
        width="100%"
        style="
          margin-top: 32px;
          height: 48px !important;
          font-size: 20px;
          font-weight: 700;
        "
        :disabled="isInvalidData"
        @click="
          isNextStep
            ? isEdit
              ? updatePromotion()
              : createPromotion()
            : (isNextStep = true)
        "
        >{{ isNextStep ? (isEdit ? "Оновити" : "Створити") : "Далі" }}
      </submit-button>
      <v-row no-gutters justify="center" style="margin-top: 12px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import requestFormData from "@/requests/requestFormData";
import submitButton from "../../UI/Buttons/submitButton.vue";
import cancelBtn from "../../UI/Buttons/cancelBtn.vue";
import discountService from "../../../requests/Admin/Discount/discountService";
import routesService from "../../../requests/Admin/routesService";
import ErrorSnackbar from "../../UI/Snackbars/errorSnackbar.vue";
import tripService from "../../../requests/Admin/tripService";
import debounce from "lodash/debounce";
export default {
  components: { cancelBtn, submitButton, ErrorSnackbar },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    promotion: {
      name: "",
      discount_type: "",
      route_type: "",
      amount_type: "Percent",
      amount: "",
      routes: [-1],
      schedules: [],
      dates: [-1],
      usage_limit: 0,
    },
    discountPeriodList: [
      { id: 1, name: "Вибір періода знижки", value: "Season" },
      { id: 2, name: "Перманентний", value: "Social" },
      //{ id: 3, name: "За кількість куплених квитків", value: "usage_limit" },
    ],
    typeDiscountActionList: [
      { id: 1, name: "На всі маршрути", value: "All" },
      { id: 2, name: `На маршрут/Маршрути`, value: "Selected" },
      { id: 3, name: `На окремий рейс`, value: "Schedule" },
    ],
    discountType: [
      { name: "Відсоток від вартості", value: "Percent" },
      { name: "Сума від вартості", value: "Fixed" },
    ],
    routesList: [],
    scheduleList: [],
    isNextStep: false,
    page: 1,
    datePickerKey: 1,
    isCycle: true,
    snackbarText: "",
    showErrorSnackbar: false,
  }),
  validations: {
    promotion: {
      name: {
        required,
      },
      discount_type: {
        required,
      },
      route_type: {
        required,
      },
      amount_type: { required },
      dates: { required },
      amount: {
        required,
      },
      routes: {
        required,
      },
    },
  },
  props: {
    isEdit: {
      require: false,
    },
    promotionForEdit: {
      require: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setPromotion();
    }
    this.getRoutes();
  },
  methods: {
    async getRoutes() {
      while (this.isCycle) {
        await routesService.getRouteForAdmin(this.page, "All").then((res) => {
          if (res.status == "Success") {
            this.routesList.push(...res.data);
            if (res.pagination.meta.last_page == this.page) {
              this.isCycle = false;
            } else {
              this.page++;
              this.isCycle = true;
            }
          }
        });
      }
    },
    async getSheduls() {
      this.scheduleList = [];
      this.promotion.routes.forEach(async (route_id) => {
        await tripService.getTripsByRouteID(route_id).then((res) => {
          if (res.status == "Success") {
            this.scheduleList.push(...res.data);
          }
        });
      });
    },
    async createPromotion() {
      this.$v.$touch();
      let tenYears = new Date();
      tenYears.setFullYear(tenYears.getFullYear() + 10);
      if (!this.isInvalidData) {
        let routes = [];
        let schedules = [];
        this.promotion.routes = this.promotion.routes.filter(
          (route) => route !== -1
        );
        this.promotion.schedules = this.promotion.schedules.filter(
          (schedule) => schedule !== -1
        );
        routes = this.promotion.routes.map(
          (route) => (route = { route_uuid: route })
        );
        schedules = this.promotion.schedules.map(
          (schedule) => (schedule = { schedule_uuid: schedule })
        );
        let data = {
          amount: this.promotion.amount * 100,
          amount_type: this.promotion.amount_type,
          route_type: this.promotion.route_type,
          routes: routes.length ? routes : "",
          schedules: schedules.length ? schedules : "",
          discount_type: this.promotion.discount_type,
          usage_limit: this.promotion.usage_limit
            ? this.promotion.usage_limit
            : null,
          status: "Active",
          started_at:
            this.promotion.dates.length > 0 &&
            this.promotion.dates[0] !== -1 &&
            this.promotion.dates[0] !== ""
              ? `${new Date(this.promotion.dates[0])
                  .toISOString()
                  .substring(0, 10)} 00:00:00`
              : `${new Date().toISOString().substring(0, 10)} 00:00:00`,
          ended_at:
            this.promotion.dates.length > 1 &&
            this.promotion.dates[1] !== -1 &&
            this.promotion.dates[1] !== ""
              ? `${new Date(this.promotion.dates[1])
                  .toISOString()
                  .substring(0, 10)} 23:59:00`
              : `${new Date(tenYears).toISOString().substring(0, 10)} 23:59:00`,
          translations: [
            {
              title: this.promotion.name,
              description: this.promotion.name,
              lang: "ua",
            },
          ],
        };
        if (
          this.promotion.routes == -1 ||
          this.promotion.routes == "" ||
          this.promotion.route_type == "Schedule"
        ) {
          delete data.routes;
        }
        if (this.promotion.schedules == -1 || this.promotion.schedules == "") {
          delete data.schedules;
        }
        let form = requestFormData.jsonToFormData(data);
        await discountService
          .createDiscount(form)
          .then((res) => {
            if (res.status == "Success") {
              this.$emit("getPromotions");
              this.$emit("close");
            }
          })
          .catch((e) => {
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.errors.route ==
                "Discount already created for one or more routes/schedules"
            ) {
              this.snackbarText =
                "Знижка вже створена для одного або кількох маршрутів/розкладів";
              this.showErrorSnackbar = true;
              setTimeout(() => {
                this.showErrorSnackbar = false;
              }, 3000);
            }
          });
      }
      //this.$emit("close");
    },
    async updatePromotion() {
      this.$v.$touch();
      let tenYears = new Date();
      tenYears.setFullYear(tenYears.getFullYear() + 10);
      let routes = [];
      let schedules = [];
      if (this.promotion.route_type == "Selected") {
        this.promotion.routes.forEach((route) => {
          console.log("ROUTE", route);
          if (typeof route == "number") {
            routes.push({ route_uuid: route });
          } else if (route && route !== "undefined-undefined") {
            routes.push({ route_uuid: route?.id || route });
          }
        });
      }
      if (this.promotion.route_type == "Schedule") {
        this.promotion.schedules.forEach((schedule) => {
          if (typeof schedule == "number") {
            schedules.push({ schedule_uuid: schedule });
          } else if (schedule && schedule !== "undefined-undefined") {
            routes.push({ schedule_uuid: schedule.id || schedule });
          }
        });
      }
      if (!this.isInvalidData) {
        let data = {
          amount: this.promotion.amount * 100,
          amount_type: this.promotion.amount_type,
          route_type: this.promotion.route_type,
          routes: routes.length > 0 ? routes : [],
          schedules: schedules.length > 0 ? routes : [],
          discount_type: this.promotion.discount_type,
          status: this.promotion.status,
          usage_limit: this.promotion.usage_limit
            ? this.promotion.usage_limit
            : null,
          started_at:
            this.promotion.dates.length > 0 &&
            this.promotion.dates[0] !== -1 &&
            this.promotion.dates[0] !== ""
              ? `${new Date(this.promotion.dates[0])
                  .toISOString()
                  .substring(0, 10)} 00:00:00`
              : `${new Date().toISOString().substring(0, 10)} 00:00:00`,
          ended_at:
            this.promotion.dates.length > 1 &&
            this.promotion.dates[1] !== -1 &&
            this.promotion.dates[1] !== ""
              ? `${new Date(this.promotion.dates[1])
                  .toISOString()
                  .substring(0, 10)} 23:59:00`
              : `${new Date(tenYears).toISOString().substring(0, 10)} 23:59:00`,
          translations: [
            {
              uuid: this.promotion.translations[0].id,
              title: this.promotion.name,
              description: this.promotion.name,
              lang: "ua",
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await discountService
          .updateDiscount(this.promotion.id, form)
          .then((res) => {
            if (res.status == "Success") {
              this.$emit("getPromotions");
              this.$emit("close");
            }
          });
      }
    },
    setPromotion() {
      //this.promotion = this.promotionForEdit;
      this.$set(
        this.promotion,
        "name",
        this.promotionForEdit?.translations?.find(
          (translate) => translate.lang == "ua"
        )?.title
      );
      this.$set(this.promotion, "amount", this.promotionForEdit?.amount);
      this.$set(
        this.promotion,
        "amount_type",
        this.promotionForEdit?.amount_type
      );
      this.$set(
        this.promotion,
        "discount_type",
        new Date(this.promotionForEdit.ended_at).getFullYear() -
          new Date(this.promotionForEdit.started_at).getFullYear() ==
          10
          ? "Social"
          : "Season"
      );
      this.$set(this.promotion, "route_type", this.promotionForEdit.route_type);
      this.$set(this.promotion, "status", this.promotionForEdit.status);
      this.$set(
        this.promotion,
        "translations",
        this.promotionForEdit.translations
      );
      this.$set(this.promotion, "id", this.promotionForEdit.id);
      setTimeout(() => {
        this.$set(
          this.promotion,
          "routes",
          this.promotionForEdit?.routes.map(
            (route) => (route = route?.info?.id)
          )
        );
        this.$set(
          this.promotion,
          "schedules",
          this.promotionForEdit?.schedules.map(
            (schedule) => (schedule = { ...schedule.info })
          )
        );
        if (this.promotionForEdit.route_type == "Schedule") {
          this.$set(
            this.promotion,
            "routes",
            this.promotionForEdit?.schedules.map(
              (schedule) => (schedule = schedule?.info?.route?.id)
            )
          );
        }
      }, 500);
      setTimeout(() => {
        this.$set(this.promotion, "dates", [
          this.promotionForEdit.started_at.substring(0, 10),
          this.promotionForEdit.ended_at.substring(0, 10),
        ]);
        this.datePickerKey++;
      }, 500);
    },
    handleCheckRoute: debounce(function () {
      this.getSheduls();
    }, 1000),
  },
  watch: {
    "promotion.discount_type": {
      deep: true,
      handler() {
        console.log("work", this.promotion.discount_type);
        if (this.promotion.discount_type == "Social") {
          this.promotion.dates = [-1];
        } else {
          this.promotion.dates = ["", ""];
        }
      },
    },
    "promotion.route_type": {
      deep: true,
      handler() {
        if (this.promotion.route_type == "All") {
          this.promotion.routes = [-1];
        } else {
          this.promotion.routes = [];
        }
      },
    },
    "promotion.routes": {
      deep: true,
      handler() {
        if (this.promotion.route_type == "Schedule") {
          this.handleCheckRoute();
        }
      },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.promotion.name;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    discountTypeError() {
      const errors = [];
      let field = this.$v.promotion.discount_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    routeTypeError() {
      const errors = [];
      let field = this.$v.promotion.route_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    amountTypeError() {
      const errors = [];
      let field = this.$v.promotion.amount_type;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    amountError() {
      const errors = [];
      let field = this.$v.promotion.amount;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    datesError() {
      const errors = [];
      let field = this.$v.promotion.dates;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    routesError() {
      const errors = [];
      let field = this.$v.promotion.routes;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    firstStepValid() {
      let field = this.$v.promotion;
      return (
        field.name.$invalid ||
        field.route_type.$invalid ||
        field.discount_type.$invalid
      );
    },
    isInvalidData() {
      if (!this.isNextStep) {
        let field = this.$v.promotion;
        return (
          field.name.$invalid ||
          field.route_type.$invalid ||
          field.discount_type.$invalid
        );
      } else {
        if (
          this.promotion.discount_type == "Social" &&
          this.promotion.route_type == "All"
        ) {
          return (
            this.$v.promotion.name.$invalid ||
            this.$v.promotion.discount_type.$invalid ||
            this.$v.promotion.route_type.$invalid ||
            this.$v.promotion.amount_type.$invalid ||
            this.$v.promotion.amount.$invalid
          );
        } else if (
          this.promotion.discount_type == "Season" &&
          this.promotion.route_type == "All"
        ) {
          return (
            this.$v.promotion.name.$invalid ||
            this.$v.promotion.discount_type.$invalid ||
            this.$v.promotion.route_type.$invalid ||
            this.$v.promotion.amount_type.$invalid ||
            this.$v.promotion.amount.$invalid ||
            this.$v.promotion.dates.$invalid ||
            this.promotion.dates[0] == "" ||
            this.promotion.dates[1] == ""
          );
        } else if (
          this.promotion.discount_type == "Social" &&
          this.promotion.route_type == "Selected"
        ) {
          return (
            this.$v.promotion.name.$invalid ||
            this.$v.promotion.discount_type.$invalid ||
            this.$v.promotion.route_type.$invalid ||
            this.$v.promotion.amount_type.$invalid ||
            this.$v.promotion.amount.$invalid ||
            this.$v.promotion.routes.$invalid
          );
        }
      }
      return this.$v.$invalid;
    },
    minDate() {
      const today = new Date();
      const yesterday = new Date(today);
      return yesterday.toISOString().substr(0, 10);
    },
    schedules() {
      return this.scheduleList.filter((schedule) =>
        this.promotion.routes.includes(schedule.route_id)
      );
    },
  },
};
</script>

<style>
.v-select__selections{
  height: 35px;
  overflow: hidden;
  white-space: nowrap;
}
</style>